


pre[data-lang]::before { content: attr(data-lang); display: block; }
pre{
  overflow-x:scroll;
  overflow-y:scroll;
  background: black;  
  color: #00ff00; 
  padding: 10px 16px;
  border-radius: 2px;
  border-top: 4px solid #00aeef;
  -moz-box-shadow: inset 0 0 10px #000;
  box-shadow: inset 0 0 10px #000;
}
pre span {
  counter-increment: line;
  display: block;
  line-height: 1.5rem;
}
pre span:before {
  counter-reset: line;
  content: counter(line);
  display: inline-block;
  border-right: 1px solid #ddd;
  padding: 0 .5em;
  margin-right: .5em;
  color: #888
}






body,html {
  font-family: 'SF Pro', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color:#2e2e2e;
  margin: 0;
  padding: 0;
  font-size: 17px;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

h1{ font-size:2rem; }

h1,h2,h3,h4,h5,h6{
  color:#9B1FD3;
  // font-family: "Overpass", sans-serif;
  font-family: "Oswald", serif;
  font-weight:800;
  letter-spacing:-0.03rem;
  line-height:1.1em;
}

.heading-icon1{
  height: 35px;
  width: 40px;
  transform: translate(-10px,6px);
  path{
    fill:#9B1FD3 !important;
  }
}

.footer-link{
  svg{
    height: 25px;
    width: 39px;
    transform: translate(0, 8px);
    path{
      fill:#9B1FD3 !important;
    }
  }
}

a, a:link, a:active, a:visited{
  color:#9B1FD3;
  text-decoration:none;
}
a:hover{ text-decoration:underline; }

.pad-img{ 
  padding:20px; 
  a{
    display:inline-block;
  }
}

.music-links-grid-container{ text-align:center; }
.music-links-grid{ 
  max-width: 500px; 
  display: inline-block;
}
.music-link-yt{ transform: translateY(9px); }

.content {
  margin: 0 auto;
  padding: 0 2em;
  max-width: 800px;
  margin-bottom: 50px;
  line-height: 1.6em;
}

.hero-content {
  position: relative;
  margin: 0 auto;
  padding: 0 2em;
  max-width: 1200px;
  margin-bottom: 50px;
  line-height: 1.6em;
}

.subpage-top{
  background-color:#210B2E;
  background-size:cover;
  color:#fff;
  text-align:center;
  padding: 20px 0 60px 0;
  margin-bottom:40px;

  .right-menu{
    position: absolute;
    top: 0px;
    right: 40px;
    opacity: 0.8;
    .right-menu-img{
      width: auto;
      height: 30px;
      border-radius: 0;
      box-shadow: none;
    }
  }
  .subpage-title{
    padding:60px 0 20px 0;
    .subpage-eyebrow{
      font-weight:bold;
      text-transform: uppercase;
      font-size:0.8em;
      line-height:1em;
    }
    .subpage-header{
      font-weight:bold;
      font-size:1.8em;
      line-height:1.2em;
      color:#e70fc5;
      padding-top:3px;
      font-family: "Oswald", sans-serif;
    }
  }
  .hero-content{
    text-align:left;
    margin-bottom:0px;
  }
}

.subpage-hero{ margin-top:-80px; }

.home-hero-content{
  background-color:#210B2E;
  background-size:cover;
  color:#fff;
  text-align:center;
  .home-hero-content-inner{
    text-align:left;
    margin: 0 auto;
    padding: 80px 40px;
    max-width: 1200px;
    line-height: 1.6em;
    display: flex;
    vertical-align: middle;
    .home-hero-l{
      display:inline-block;
      vertical-align: middle;
      padding:0 40px;
    }
    .home-hero-r{
      display:inline-block;
      width:auto;
      vertical-align: middle;
    }
    .home-hero-image{
      border-radius:9999px;
    }
    .home-hero-role{
      font-weight:bold;
    }
    .home-hero-name{
      color:#e70fc5;
      font-family: "Oswald", sans-serif;
      font-weight:800;
      font-size: 2rem;
      line-height: 2rem;
    }
    .home-hero-info{
      font-size: 1.5rem;
      line-height: 1.8rem;
    }
  }
}

.subpagelogo{
  .subpagelogo-image{
    vertical-align: middle;
    border-radius: 999px;
    display: inline-block;
    width: 37px;
    box-shadow: none;
  }
  .subpagelogo-right{
    display: inline-block;
    vertical-align: middle;
      padding-left: 10px;
    .subpagelogo-name{
      font-weight:bold;
      font-size:1.2em;
      font-family: "Oswald", sans-serif;
      color:#e70fc5;
    }
    .subpagelogo-title{
      font-weight:bold;
      font-size:0.8em;
      line-height:1em;
      font-family: "Oswald", sans-serif;
      color:#fff;
    } 
  }
}

.home-hero-logo-small{
  display:none;
}

.logogarden{
  display:block;
  height:94px;
  box-sizing: border-box;
  background-position: 0 0;
  animation: logogarden 50s linear infinite;
  margin-bottom: 40px;

}

@keyframes logogarden {
  from { background-position: 0 0; }
  to { background-position: -1964px 0px; }
}

@media only screen and (max-width: 800px) {
  .home-hero-content{
    .home-hero-content-inner{
      .home-hero-l{}
      .home-hero-r{}
      .home-hero-image{}
      .home-hero-role{}
      .home-hero-name{}
      .home-hero-info{
        font-size: 1.2rem;
        line-height: 1.2em;
      }
    }
  }
}

@media only screen and (max-width: 570px) {
  .home-hero-content{
    .home-hero-content-inner{
      .home-hero-l{}
      .home-hero-r{}
      .home-hero-image{ display:none; }
      .home-hero-role{}
      .home-hero-name{}
      .home-hero-info{
        font-size: 1.2rem;
        line-height: 1.2em;
      }
    }
  }
  .home-hero-logo-small{
    display:block;
    zoom:1.2;
  }
  .home-hero-name, .home-hero-role{ display:none; }
}

@media only screen and (max-width: 540px) {
  .home-hero-content{
    .home-hero-content-inner{
      .home-hero-l{ padding:0; }
      .home-hero-r{}
      .home-hero-image{ display:none; }
      .home-hero-role{}
      .home-hero-name{}
      .home-hero-info{}
    }
  }
}

img {
  max-width: 100%;
  height: auto;
}

.hero-content img{
  border-radius:20px;
  box-shadow: 0px 0px 10px 10px #0000001a;
}

.inline-img{
  border-radius:10px;
  box-shadow: 0px 0px 5px 5px #0000001a;
}

.img-annotation{
  text-align: center;
  font-size: 0.8rem;
  font-style: italic;
  color: #7e7e7e;
}

.spacer-above{ margin-top:40px; }
.spacer-above-big{ margin-top:80px; }
.vsts-thumbs{ text-align:center; }

.hp-work-item{
  display:inline-block;
  margin-bottom:40px;
  border-radius:5px;
  box-shadow: 0px 0px 5px 5px #0000001a;
  padding:20px;
  text-align:left;
  .hp-work-inner{
    color:#2e2e2e;
    .hp-work-img-container{
      border-radius:3px;
      background:#ccc;
      height: 300px;
      overflow:hidden;
      background-position: center center;
      background-size: cover;
      .hp-work-img{
        height: 300px;
        width:760px !important;
        margin: auto;
        display: block;
      }
    }
    .hp-work-content{
      .hp-work-title{
        padding:20px 0px 0px 0px;
        font-size:1.2em;
        font-weight:bold;
      }
      .hp-work-skills{
        padding:20px 0px;
        font-size:1.02em;
        font-weight:bold;
      }
      .hp-work-info{
      }
    }
  }
  &.small{
    box-shadow: 0px 0px 3px 3px #0000001a;
    vertical-align: top;
    max-width: 330px;
    margin-right: 30px;
    padding:15px;
    .hp-work-img-container{
      height: 200px;
    }
  }
}

@media only screen and (max-width: 788px) {
  .hp-work-item{
    &.small{
      margin-right: 0;
    }
  }
}

@keyframes shimmer {
   to {
      background-position-x: 0%
   }
}

a.hp-work-item:hover{ 
  background: linear-gradient(-45deg, #fff 40%, #f5e5ff 50%, #fff 60%);
  background-size: 500%;
  background-position-x: 100%;
  animation: shimmer 1.5s infinite linear;
  text-decoration:none;
  .hp-work-img{
  }
}

.hp-work-cta{
  text-align:center;
  padding:20px 0 5px 0 ;
  .pure-button{
    padding: .5em 3em;
    color: #9b1ed3;
    border: 2px solid #9b1ed3;
    background-color: transparent;
    text-decoration: none;
    border-radius: 999px;
    font-weight: bold;
  }
}
              
.pure-button-hover, .pure-button:focus, .pure-button:hover{
  background: transparent;
}

.footer{
  background: #f7ecff;
  padding: 40px 40px;
  border-radius:20px;
  margin-bottom:20px;
}

.blockquote-large{
  font-size: 1.5em;
  line-height: 1.5em;
  background: #a7ffe3;
  padding: 1px 30px;
  border-radius: 10px;
  border: 3px dashed #28ca96;
}

.blockquote{
  border-left: 4px solid #9b1fd3f7;
    transform: translateX(-25px);
    background: #9b1fd308;
    font-size: 0.8em;
    line-height: 1.6em;
    font-style: italic;
    font-weight: bold;
    padding: 10px 20px;
}